import { createElement, ComponentType } from 'react';
import { RouterProps } from '@interactivevision/visitor-react';
import { Cookies } from './cookies/components/cookies';


declare var CookieConsentApi: any;
declare var initCookieConsent: any;

type SetupOptions = {
  router: ComponentType<RouterProps>;
  props: RouterProps;
}

export const app = ({ router, props }: SetupOptions) => {
  return (
    <>
      {createElement(router, props)}
      <Cookies/>
    </>
  );
};
