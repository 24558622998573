import { FunctionComponent } from 'react';
import styles from './footer.module.scss';
import { ReactComponent as CompanyLogo } from '../../../assets/company-logo.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/phone.svg';
import { ReactComponent as PinIcon } from '../../../assets/pin.svg';
import wrappers from './wrappers.module.scss';
import classNames from 'classnames';
import { Link, useShared } from '@interactivevision/visitor-react';
import { ReactComponent as IvLogo } from '../../../assets/iv-logo.svg';
import { $t } from '@interactivevision/translations';

export const Footer: FunctionComponent = () => {
  const { menu, contact } = useShared();

  return (
    <footer className={styles.footer}>
      <section className={classNames(styles.upper, wrappers.section)}>
        <CompanyLogo className={styles.logo}/>
        <div className={styles.content}>
          <div className={styles.contact}>
            <PinIcon/>
            <div>
              {contact.street}<br/>
              {contact.postal_code + ' ' + contact.city}
            </div>
          </div>
          <div className={styles.contact}>
            <PhoneIcon/>
            <a href={`tel:${contact.phone_code + contact.phone_number}`} className={styles.phone}>
              <sup>{contact.phone_code}</sup>
              {contact.phone_number}
            </a>
          </div>
        </div>
        <div className={styles.email}>
          <a href={`mailto:${contact.email}`}>{contact.email}</a>
        </div>
      </section>
      <section className={styles.middle}>
        <div className={classNames(styles.content, wrappers.section)}>
          <a href="https://www.facebook.com/aromasystempl" target="_blank" rel="noreferrer">facebook</a>
          {/*<a href="" target="_blank" rel="noreferrer">instagram</a>*/}
          <a href="https://www.linkedin.com/company/aroma-system/" target="_blank" rel="noreferrer">linkedin</a>
          <a href="https://www.youtube.com/@AromaSystem" target="_blank" rel="noreferrer">youtube</a>
        </div>
      </section>
      <section className={classNames(styles.lower, wrappers.section)}>
        <span>2023 © Aroma systems. All rights reserved</span>
        <div className={styles.content}>
          {menu.bottom.map((item) => (
            <div key={item.url}>
              <Link href={item.url} rel={item.rel}>
                {item.name}
              </Link>
            </div>
          ))}
        </div>
        <div className={styles.realization}>
          <span>{$t('web.realization')}</span>
          <a href="https://interactivevision.pl/" target="_blank" rel="noreferrer" title="InteractiveVision">
            <IvLogo/>
          </a>
        </div>
      </section>
    </footer>
  );
};
