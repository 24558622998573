import { createRoot, hydrateRoot } from 'react-dom/client';
import { createElement } from 'react';
import { createVisitor } from '@interactivevision/visitor-react';
import { Base } from './layout/base';
import { app } from './app';

createVisitor({

  resolve: async (name: string) => {
    const components = import.meta.glob(`./views/**/*.tsx`, { eager: false });
    const component: any = await components[`./views/${name}.tsx`]();

    component.default.layout = component.default.layout || Base;

    return component;
  },

  setup: (options) => {
    if (import.meta.hot) {
      createRoot(document.getElementById('app') as HTMLElement).render(createElement(app, options));
    } else {
      hydrateRoot(document.getElementById('app') as HTMLElement, createElement(app, options));
    }
  },

});
