import { FunctionComponent, useState } from 'react';
import styles from './navbar.module.scss';
import classNames from 'classnames';
import { ReactComponent as LoopIcon } from '../../../../assets/loop.svg';
import { ReactComponent as CompanyLogo } from '../../../../assets/company-logo.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/phone.svg';
import { NavbarMenuItem, MenuItem } from './NavbarMenuItem';
import { Link, useShared } from '@interactivevision/visitor-react';
import { route } from '@interactivevision/routing';
import { $t } from '@interactivevision/translations';


export const Navbar: FunctionComponent = () => {
  const [active, setActive] = useState(false);
  const [activeLink, setActiveLink] = useState<string>();

  const { menu, contact } = useShared();

  const closeMenu = () => setActive(false);

  // todo add active to link
  return (
    <div className={styles.container}>
      <div className={styles.upper}>
        <Link href={route('homepage_module')} className={classNames(styles.mobileLogo)} title="Aroma System">
          <CompanyLogo onClick={() => setActive(false)}/>
        </Link>
        <a href={`tel: ${contact.phone_code + contact.phone_number}`} className={styles.phone} title={$t('web.phone') || ''}>
          <span>{contact.phone_code + ' ' + contact.phone_number}</span>
          <PhoneIcon/>
        </a>
        <Link href={route('search')} className={styles.search} title="Search">
          <LoopIcon/>
        </Link>
        {/*<LanguagePicker/>*/}
        <button className={classNames(styles.burger, { [styles.active]: active })} onClick={() => setActive(!active)} aria-label="Menu">
          <span className={classNames(styles.line, styles.line1)}/>
          <span className={classNames(styles.line, styles.line2)}/>
          <span className={classNames(styles.line, styles.line3)}/>
          <span className={classNames(styles.line, styles.line4)}/>
        </button>
      </div>
      <nav className={classNames(styles.lower, { [styles.active]: active })}>
        <ul className={classNames(styles.menu, styles.menuLeft)}>
          {menu.main.slice(0, Math.round(menu.main.length / 2)).map((element: MenuItem) => (
            <NavbarMenuItem
              item={element}
              active={activeLink === element.url || activeLink === undefined}
              onMouseEnter={() => setActiveLink(element.url || '')}
              onMouseLeave={() => setActiveLink(undefined)}
              onClick={closeMenu}
              key={element.name}
            />
          ))}
        </ul>
        <Link href={route('homepage_module')} className={styles.logo} title="Aroma System">
          <CompanyLogo/>
        </Link>
        <ul className={classNames(styles.menu, styles.menuRight)}>
          {menu.main.slice(Math.round(menu.main.length / 2)).map((element: MenuItem) => (
            <NavbarMenuItem
              item={element}
              active={activeLink === element.url || activeLink === undefined}
              onMouseEnter={() => setActiveLink(element.url || '')}
              onMouseLeave={() => setActiveLink(undefined)}
              onClick={closeMenu}
              key={element.name}
            />
          ))}
        </ul>
      </nav>
    </div>
  );
};
