import { Navbar } from './navbar/navbar';
import { Footer } from './footer';
import 'aos/dist/aos.css';
import styles from './base.module.scss';


export function Base({ children }: any) {

  return (
    <main className={styles.container}>
      <Navbar/>
      {children}
      <Footer/>
    </main>
  );
}
