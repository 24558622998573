import { FunctionComponent, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { Link } from '@interactivevision/visitor-react';
import styles from './navbar.module.scss';
import wrappers from '../wrappers.module.scss';
import { ReactComponent as ChevronDown } from '../../../../assets/chevron-down.svg';
import classNames from 'classnames';


export type MenuItem = {
  url: string | null;
  name: string | null;
  target: '_blank' | null;
  rel: string | null;
  children: MenuItem[],
}

export type NavbarMenuItemProps = HTMLAttributes<HTMLLIElement> & {
  active?: boolean;
  item: MenuItem;
}

export const NavbarMenuItem: FunctionComponent<NavbarMenuItemProps> = ({ item, active, onClick, ...props }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [transition, setTransition] = useState<boolean>(false);

  const submenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      setTimeout(() => setTransition(true), 10);
    } else {
      setTransition(false);
    }
  }, [open]);

  const toggleSubmenu = (event: any) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const toggleMenu = (event) => {
    item.url && typeof onClick === 'function' && onClick(event);
  };

  return (
    <li onClick={toggleMenu} {...props} className={classNames({ [styles.active]: active }, { [styles.inactive]: !active })}>
      <div className={styles.link}>
        {item.url ? (
          <Link href={item.url} rel={item.rel}>{item.name}</Link>
        ) : (
          <span>{item.name}</span>
        )}
        {item.children.length > 0 && (
          <button className={styles.opener} aria-label="Open menu" onClick={toggleSubmenu}>
            <ChevronDown/>
          </button>
        )}
      </div>
      {item.children.length > 0 && (
        <div className={classNames(styles.submenu, { [styles.active]: open }, { [styles.transition]: transition })} ref={submenuRef}>
          <ul className={wrappers.section}>
            {item.children.map(child => (
              <li key={child.url}>
                <Link href={child.url || ''} rel={child.rel || undefined}>
                  {child.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};
